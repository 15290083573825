/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Map = (props) => {
 const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>

<div className="w-20% mt-[60px]">
        <h2 className="text-center">We Cover {rpdata?.dbPrincipal?.miles} Miles Around Of:</h2>
      </div>

      <div className="flex flex-col md:flex-row w-full h-auto md:ml-2 md:mb-8 " >

      <div className="md:w-[20%] w-full md:pr-4 ">
        
        <iframe src={rpdata?.dbPrincipal?.location?.[0].url} className="w-full h-[500px]"/>
      </div>
      <div className="md:w-[20%] w-full md:pr-4 ">
        
        <iframe src={rpdata?.dbPrincipal?.location?.[1].url} className="w-full h-[500px]"/>
      </div>
      <div className="md:w-[20%] w-full md:pr-4 ">
        
        <iframe src={rpdata?.dbPrincipal?.location?.[2].url} className="w-full h-[500px]"/>
      </div>
      <div className="md:w-[20%] w-full md:pr-4 ">
        
        <iframe src={rpdata?.dbPrincipal?.location?.[3].url} className="w-full h-[500px]"/>
      </div>
      <div className="md:w-[20%] w-full md:pr-4 ">
        
        <iframe src={rpdata?.dbPrincipal?.location?.[4].url} className="w-full h-[500px]"/>
      </div>
      <div className="md:w-[20%] w-full md:pr-4 ">
        
        <iframe src={rpdata?.dbPrincipal?.location?.[5].url} className="w-full h-[500px]"/>
      </div>
      
      

      </div>
    </div>
  )
}

export default Map
